import React from "react";
import * as styles from "./IntegrateYourResearch.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Typography } from "..";
import Button from "@components/Button_new/Button";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";

type IntegrateYourResearchProps = {};

export const IntegrateYourResearch = ({}: IntegrateYourResearchProps) => {
	return (
		<BackgroundFadeContainer type="blueFade" skewTop skewBottom>
			<BackgroundShapes
				hideLtDesktop={false}
				rightShape={{
					shape: (
						<StaticImage
							loading="eager"
							className={styles.image}
							src={"../../assets/products/integration-logos.png"}
							alt="Integrate your research on other digital platforms with Genemod"
						/>
					),
				}}
			>
				<div className={styles.integrateYourResearch}>
					<div className={styles.textAndButton}>
						<Typography
							variant="HEADER1"
							color="ui-01"
							className={styles.title}
						>
							Integrate your research workflows and database
						</Typography>
						<Button link="DEMO">Learn more</Button>
					</div>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
};
