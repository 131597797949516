import React from "react";
import SVG from "react-inlinesvg";
import * as styles from "./CentralizedPlatform.module.scss";
import CentralizedPlatformLight from "assets/products/centralized-platform-light.svg";
import CentralizedPlatformDark from "assets/products/centralized-platform-dark.svg";
import { useWindowSize } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";

export type CentralizedPlatformProps = {
	light?: boolean;
	style?: React.CSSProperties;
};
export default function CentralizedPlatform({
	light,
	style,
}: CentralizedPlatformProps) {
	const { isMobile } = useWindowSize();
	if (isMobile) {
		return light ? (
			<StaticImage
				loading="eager"
				src={
					"../../assets/products/centralized-platform-light(mobile).png"
				}
				alt={"centralized-platform-light(mobile)"}
			/>
		) : (
			<StaticImage
				loading="eager"
				src={
					"../../assets/products/centralized-platform-dark(mobile).png"
				}
				alt={"centralized-platform-dark(mobile)"}
			/>
		);
	}
	return light ? (
		<SVG
			src={CentralizedPlatformLight}
			className={styles.animation}
			style={style}
		/>
	) : (
		<SVG
			src={CentralizedPlatformDark}
			className={styles.animation}
			style={style}
		/>
	);
}
