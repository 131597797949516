import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkCircles0,
	PinkCircles1,
	PinkSquares1,
} from "@assets/Background";
import { PageLayout } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { GetStartedButton } from "@components/Button_new/Button";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { pinkText } from "@components/Typography/Typography";
import { StaticImage } from "gatsby-plugin-image";
import { IntegrateYourResearch } from "@components/IntegrateYourResearch/IntegrateYourResearch";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import CentralizedPlatform from "@components/CentralizedPlatform/CentralizedPlatform";

export default function Inventory() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "lab-inventory-management-software",
			}}
		>
			<Hero />
			<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
				<OnlySolution />
				<InventoryManagement />
			</BackgroundFadeContainer>
			<IntegrateYourResearch />
			<Search />
			<LearnMoreSection background="blueFade" textColor="ui-01" />
		</PageLayout>
	);
}

function Hero() {
	return (
		<BackgroundFadeContainer type="blueFade" skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <BlueSquares1 />,
					xOffset: -70,
					yOffset: 165,
				}}
				rightShape={{
					shape: <BlueSquares2 />,
					yOffset: 400,
				}}
			>
				<div
					style={{
						marginBottom: 40,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
					}}
				>
					<SectionTitle
						title="It’s time to visualize your inventory"
						subtitle="With Genemod Inventory, you can centralize all your spreadsheets onto a single platform as a source of truth."
						textColor="ui-01"
						titleWidth={814}
						subtitleWidth={728}
						marginTop={163}
						marginTopTablet={186}
						marginTopMobile={146}
					/>
					<GetStartedButton />
					<CentralizedPlatform style={{ marginTop: 24 }} />
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function OnlySolution() {
	return (
		<BackgroundShapes
			leftShape={{ shape: <></> }}
			rightShape={{
				shape: <PinkCircles1 />,
				xOffset: 100,
				yOffset: 300,
			}}
		>
			<SectionTitle
				title={pinkText`The ${"only solution"} that doesn’t look like another spreadsheet`}
				textColor="text-dark"
				titleWidth={954}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/freezer-rack.png"
						alt="freezer rack"
						width={786}
						height={586}
					/>
				}
				bullets={[
					{
						title: "Fully customizable - from shelves to racks and cryoboxes",
						text: "Custom design a virtual freezer that matches its real-life counterpart, shelf for shelf and item for item.",
					},
					{
						title: "Easily create categories - because some items are bulkier than others",
						text: "Categories allow you to group similar items together that don’t fit in a cryobox.",
					},
					{
						title: "Bookmark commonly used items or flag to reorder",
						text: "Bookmark reagents to easily access at a later time or make a note to reorder reagents that are running low.",
					},
				]}
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "marine",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	);
}

function InventoryManagement() {
	return (
		<BackgroundShapes
			leftShape={{ shape: <></> }}
			rightShape={{
				shape: <PinkCircles0 />,
				xOffset: -150,
				yOffset: 420,
			}}
		>
			<SectionTitle
				title={"All the features you need for inventory management"}
				textColor="text-dark"
				titleWidth={780}
				marginTop={142}
				marginTopTablet={116}
				marginTopMobile={60}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/Freezer_box.png"
						alt="freezer box"
						width={786}
						height={586}
					/>
				}
				bullets={[
					{
						title: "We’ve built your favorite Excel-like features to make data entry painless",
						text: "Keep track of item types, concentrations, attachments, and notes of any samples and reagents stored in your lab.",
					},
					{
						title: pinkText`Create ${"custom item types"} or choose from our templates`,
						text: "Default item templates include primer, strain, antibody, chemical, enzyme, cell line, and more.",
					},
					{
						title: "Generate automations for 100+ use cases",
						text: "Create automations that alert you when items are running low or when updates have been made.",
					},
				]}
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "marine",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	);
}

function Search() {
	return (
		<BackgroundFadeContainer type="solidLightPink" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{ shape: <></> }}
				rightShape={{
					shape: <PinkSquares1 />,
					xOffset: 100,
					yOffset: 260,
				}}
			>
				<SectionTitle
					title={
						"We’ve built the most robust search engine for your samples"
					}
					textColor="text-dark"
					titleWidth={920}
					marginBottom={60}
					marginBottomTablet={60}
				/>
				<ImageAndBulletPoints
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/freezer-advanced-search.png"
							alt="protocol library"
							width={786}
							height={586}
						/>
					}
					bullets={[
						{
							title: "Advanced search features help find samples faster",
							text: "Easily filter, find, and navigate to the samples and categories that you’re looking for.",
						},
						{
							title: pinkText`Easily ${"import items"} from Excel`,
							text: "Once you’ve uploaded your spreadsheet, from there you can easily match data columns to migrate at scale.",
						},
						{
							title: "A single source of truth",
							text: "Data entry and compliance is easier when everyone is on the same page.",
						},
					]}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "marine",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
